import React, { useRef, useEffect, memo, useState} from "react";
import s from "./index.module.less";
import { gsap } from "gsap";
    
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { 
    partnerData
} from "../utils/config.js";
import Attr from "../Attr";
import { ScrollToPlugin } from "gsap/all";
import CustomIcon from '../../components/CustomIcon';
import { ReactSVG } from "react-svg";

//import { ScrollSmoother } from "gsap-trial/all";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
//gsap.registerPlugin(ScrollSmoother);

const AlphaPhoneVerticalTimeline = ({
    verticalMode = false,
})=>{
    const tlTriggerRef = useRef(null);
    const turnupSvgRef = useRef(null);
    const rootRef = useRef(null);
    const tl1Ref = useRef(null);
    const scrollContentRef = useRef(null);
    const [isHovering, setIsHovering] = useState(false);
    const [isOverWidth, setIsOverWidth] = useState(false);

    window.addEventListener('resize', function (event) {
        if (!isOverWidth && window.innerWidth / window.innerHeight > 4){
            setIsOverWidth(true);
        }
        else if (isOverWidth && window.innerWidth / window.innerHeight < 4){
            setIsOverWidth(false);
        }
    });

    function playAppear(target, duration) {
        if (target.reversed() || target.progress() === 0){
            target.duration(duration);
            target.play(0.001);
            //console.log('onStart');
        }
    }
    
    function playDisappear(target, duration) {
        target.duration(duration);
        target.reverse();
        //console.log('onComplete');
    }

    //text part
    const text1MinValue= 28;
    const text1MaxValue= 124;
    const textMinSize= 300;
    const textMaxSize= 1500;

    const text2MinValue= 35;
    const text2MaxValue= 70;
    const longTextMinValue = 20;
    const longTextMaxValue = 40;
    const longTextImportantMinValue = 30;
    const longTextImportantMaxValue = 60;
    const comingSoonText1MinValue = 40;
    const comingSoonText1MaxValue = 150;
    const comingSoonText2MinValue = 28;
    const comingSoonText2MaxValue = 80;

    const text2Ref = useRef(null);
    const text2Part1Ref = useRef(null);
    const text2Part2Ref = useRef(null);
    const text2Part3Ref = useRef(null);
    const text2Part4Ref = useRef(null);

    const getText1Size = () =>{
        return `calc(${text1MinValue}px + ${text1MaxValue - text1MinValue} * (100vw - ${textMinSize}px) / ${textMaxSize - textMinSize})`;
    }

    const getText2Size = () =>{
        return `calc(${text2MinValue}px + ${text2MaxValue - text2MinValue} * (100vw - ${textMinSize}px) / ${textMaxSize - textMinSize})`;
    }

    const getComingSoonText1FontSize = () =>{
        return `calc(${comingSoonText1MinValue}px + ${comingSoonText1MaxValue - comingSoonText1MinValue} * (100vw - ${textMinSize}px) / ${textMaxSize - textMinSize})`;
    }

    const getComingSoonText2FontSize = () =>{
        return `calc(${comingSoonText2MinValue}px + ${comingSoonText2MaxValue - comingSoonText2MinValue} * (100vw - ${textMinSize}px) / ${textMaxSize - textMinSize})`;
    }

    const getLongTextTextSize = () =>{
        return `calc(${longTextMinValue}px + ${longTextMaxValue - longTextMinValue} * (100vw - ${textMinSize}px) / ${textMaxSize - textMinSize})`;
        //return `40px`;
    }

    const getLongTextImportantTextSize = () =>{
        return `calc(${longTextImportantMinValue}px + ${longTextImportantMaxValue - longTextImportantMinValue} * (100vw - ${textMinSize}px) / ${textMaxSize - textMinSize})`;
        //return `80px`;
    }

    const page2LongText1RootRef = useRef(null);
    const page2LongText11Ref = useRef(null);
    const page2LongText11HighLightRef = useRef(null);
    const page2LongText12Ref = useRef(null);
    const page2LongText12HighLightRef = useRef(null);
    const page2LongText1RefList = [page2LongText11Ref, page2LongText12Ref];
    const page2LongText1HighLightRefList = [page2LongText11HighLightRef, page2LongText12HighLightRef];

    const page2ImageRootRef = useRef(null);

    const page3LongText1RootRef = useRef(null);
    const page3LongText1P1Ref = useRef(null);
    const page3LongText1P2Ref = useRef(null);
    const page3LongText1RefList = [page3LongText1P1Ref, page3LongText1P2Ref];

    const longTextColorGrayLight = `rgba(150,150,150,1)`;
    const longTextColorLight = `rgba(255,255,255,1)`;
    const longTextHighLightColor = `#ffda0a`;

    const video_section1_ref = useRef(null);
    const video_section2_ref = useRef(null);
    //const video_section3_ref = useRef(null);

    const setLighLightLine = (textRefList, importantTextRefList, highLightParagh) =>{
        if (textRefList?.length > 0){
            for (let i=0;i<textRefList.length;i++){
                if (textRefList?.[i]?.current){
                    textRefList[i].current.style.color = highLightParagh === i?longTextColorLight:longTextColorGrayLight;
                }
                if (importantTextRefList?.[i]?.current){
                    importantTextRefList[i].current.style.color = highLightParagh === i?longTextHighLightColor:longTextColorGrayLight;
                }
            }
        }
    }

    const launchApp = () =>{
        window.open('https://store.turnup.xyz/?pswutlzoq=install', '_self');
    }

    useEffect(()=>{
        const vp1 = video_section1_ref.current;
        if (vp1 && vp1.webkitSetPresentationMode) vp1.webkitSetPresentationMode("inline");

        const vp2 = video_section2_ref.current;
        if (vp2 && vp2.webkitSetPresentationMode) vp2.webkitSetPresentationMode("inline");
    
        /*const vp3 = video_section3_ref.current;
        if (vp3 && vp3.webkitSetPresentationMode) vp3.webkitSetPresentationMode("inline");*/

        if (turnupSvgRef?.current){
            let elements = turnupSvgRef.current.getElementsByTagName("animate");
            console.log("getEle", elements);
            for (let i = 0;i<elements.length;i++){
                elements[i].beginElement();
            }
        }

    }, []);

    const handleMouseEnter = () => {
        console.log(`Enter`);
        if (!verticalMode){
            setIsHovering(true);
            scrollContentRef.current.style.animationPlayState = 'paused'; 
        }
    };
    
    const handleMouseLeave = () => {
        console.log(`Leave`);
        if (!verticalMode){
            setIsHovering(false);
            scrollContentRef.current.style.animationPlayState = 'running';
        }
    };

    const openUrl = (url) =>{
        if(url){
            window.open(url);
        }
    }

    const {contextSafe} = useGSAP(
        () => {

            /*
            let smoother = ScrollSmoother.create({
                smooth: 1,
                effects: true,
                normalizeScroll: false,
                speed:1,
                content:'.tlTrigger',
              });*/
            //smoother.effects('.tlTrigger',{speed: 1, lag: 0.1});
            
            //const maxScrollY = ScrollTrigger.maxScroll(window, false);
            //const pos = tl.scrollTrigger.labelToScroll("buyPhone");

            let trigger = {
                trigger: ".tlTrigger",
                start: "top top",
                end: "bottom bottom",
                //markers: true,
                scrub: 1,
                //snap: "labelsDirectional",
                
                
                /*snap:{
                    snapTo:"labelsDirectional", //[0, 0.1, 0.15, 0.18, 0.21], 
                    directional:false,
                    duration:{min: 0.3, max: 0.5},
                    delay:0.01,
                    inertia: false,
                    //ease:"power1.inout",
                }*/
            };
            
            let tl1 = gsap.timeline({
                scrollTrigger: trigger, defaults: { ease: "power1.inout" }
            });

            tl1Ref.current = tl1;

            const text1StartFrame = 0;
            const text1LastFrame = 2;
            const text2StartFrame =  text1StartFrame + text1LastFrame;
            const text2LastFrame = 20;
            const page2StartFrame = text2StartFrame + text2LastFrame;
            const page2LastFrame = 23;
            const page3StartFrame = page2StartFrame + page2LastFrame;
            //label
            tl1.addLabel("text1", text1StartFrame);
            tl1.addLabel("text2", text2StartFrame);
            tl1.addLabel("page2", page2StartFrame);
            tl1.addLabel("page3", page3StartFrame);

            tl1.addLabel("text2_2", text2StartFrame + 5);
            tl1.addLabel("text2_3", text2StartFrame + 10);
            tl1.addLabel("text2_4", text2StartFrame + 15);
            tl1.addLabel("page2longText_2", page2StartFrame + 6);
            tl1.addLabel("page2ImageRoll_1", page2StartFrame + 12);
            //tl1.addLabel("page2ImageRoll_2", page2StartFrame + 15);
            tl1.addLabel("page3Text1_2", page3StartFrame + 8);
            tl1.addLabel("end", page3StartFrame + 20);

            //tween
            let text2InTween = gsap.to(".text2", {
                duration: 1,
                autoAlpha: 1,
                fontSize: `calc(${text2MinValue}px + ${text2MaxValue - text2MinValue} * (100vw - ${textMinSize}px) / ${textMaxSize - textMinSize} + 30px)`
            });
            text2InTween.pause();

            let text2Part1InTween = gsap.to(".text2Part1", {
                duration: 0.5,
                autoAlpha: 1,
                marginTop: verticalMode?'15px':'-10px'
            });
            text2Part1InTween.pause();

            let text2Part2InTween = gsap.to(".text2Part2", {
                duration: 0.5,
                autoAlpha: 1,
                marginTop: verticalMode?'15px':'-10px'
            });
            text2Part2InTween.pause();

            let text2Part3InTween = gsap.to(".text2Part3", {
                duration: 0.5,
                autoAlpha: 1,
                marginTop: verticalMode?'15px':'-10px'
            });
            text2Part3InTween.pause();

            let text2Part4InTween = gsap.to(".text2Part4", {
                duration: 0.5,
                autoAlpha: 1,
                marginTop: verticalMode?'15px':'-10px'
            });
            text2Part4InTween.pause();

            let text2OutTween = gsap.to(".text2", {
                duration: 0.5,
                autoAlpha: 0,
                top: '0%'
            });
            text2OutTween.pause();

            let page2LongText1InTween = gsap.to(".page2LongText1", {
                duration: 0.5,
                autoAlpha: 1,
                top: '50%'
            });
            page2LongText1InTween.pause();

            let page2LongText1OutTween = gsap.to(".page2LongText1", {
                duration: 0.5,
                autoAlpha: 0,
                top: '10%'
            });
            page2LongText1OutTween.pause();

            let page2LongText1ChangeTween = gsap.to(".page2LongText1", {
                duration: 0.5,
                transform: verticalMode? `translate(-50%, -75%)`:`translate(-50%, -65%)`,
            });
            page2LongText1ChangeTween.pause();

            let page2ImageRootInTween = gsap.to(".page2ImageRoot", {
                duration: 0.5,
                autoAlpha: 1,
                top: verticalMode?'30%' :'25%'
            });
            page2ImageRootInTween.pause();

            let page2ImageRootOutTween = gsap.to(".page2ImageRoot", {
                duration: 0.5,
                autoAlpha: 0,
                top: '0%'
            });
            page2ImageRootOutTween.pause();

            let page3LongText1InTween = gsap.to(".page3LongText1", {
                duration: 0.5,
                autoAlpha: 1,
                top: verticalMode?'60%':'50%'
            });
            page3LongText1InTween.pause();

            let page3LongText1OutTween = gsap.to(".page3LongText1", {
                duration: 0.5,
                autoAlpha: 0,
                top: '10%'
            });
            page3LongText1OutTween.pause();

            let page3LongText1ChangeTween = gsap.to(".page3LongText1", {
                duration: 0.5,
                transform: verticalMode? `translate(-50%, -85%)`:`translate(-50%, -75%)`,
            });
            page3LongText1ChangeTween.pause();

            let comingSoonInTween = gsap.to(".comingSoonText1", {
                duration: 1,
                autoAlpha: 1,
                scale: 1,
                onComplete: () =>{
                    comingSoon2InTween.restart();
                }
            });
            comingSoonInTween.pause();

            let comingSoon2InTween = gsap.to(".comingSoonText2", {
                duration: 1,
                autoAlpha: 1,
                scale: 1,
            });
            comingSoon2InTween.pause();

            /*let iconListInTween = gsap.to(".iconList", {
                duration: 2,
                autoAlpha: 1,
            });
            iconListInTween.pause();*/
            
            //timeline
            //text1
            gsap.set('.text1', {autoAlpha: 0}, "text1");
            const upNetworkTitleTween = gsap.to('.text1', { 
                duration: 0.3,  
                delay:0.1,
                //fontSize: `calc(${text1MinValue}px + ${text1MaxValue - text1MinValue} * (100vw - ${text1MinSize}px) / ${text1MaxSize - text1MinSize} + 30px)` ,
                autoAlpha: 1,
                //width: verticalMode?'80%':'50%',
                ease:"power3.in",
                onUpdate : async()=>{
                    onClickGoTop();
                    const vp = video_section1_ref.current;
                    if (vp && vp.paused){vp.currentTime = 0;try{await vp.play();}catch(e){console.error(e)}}
                },
            }, "text1");
            const videoSection1InTween = gsap.to(".video_section1", { 
                duration: 1,  
                delay:0.1,
                autoAlpha: 0.9,//0.4
                ease:"power1.out",
            }, "text1");
            
            tl1.to('.text1', { 
                duration: 1, 
                autoAlpha: 0,
                onReverseComplete: () =>{
                    text2Part1InTween.seek(0);
                    text2Part1InTween.pause();
                }
            }, "text1+=1");

            //text2
            tl1.to('.text2Part1', {
                duration: 0.01,
                onReverseComplete: () =>{
                    text2Part2InTween.seek(0);
                    text2Part2InTween.pause();
                    text2Part1InTween.reverse();
                },
                onStart: () =>{
                    text2Part1InTween.play();
                }
            }, "text2");
            tl1.to('.text2Part1', {
                duration: 5,
            }, ">");
            tl1.to('.text2Part2', {
                duration: 0.01,
                onReverseComplete: () =>{
                    text2Part2InTween.reverse();
                },
                onStart: () =>{
                    text2Part2InTween.play();
                }
            }, ">");
            tl1.to('.text2Part2', {
                duration: 5,
                onReverseComplete: () =>{
                    text2Part3InTween.seek(0);
                    text2Part3InTween.pause();
                },
                onComplete: () =>{
                    text2Part2InTween.seek(0.5);
                    text2Part2InTween.pause();
                }
            }, ">");
            tl1.to('.text2Part3', {
                duration: 0.01,
                onReverseComplete: () =>{
                    text2Part3InTween.reverse();
                },
                onStart: () =>{
                    text2Part3InTween.play();
                }
            }, ">");
            tl1.to('.text2Part3', {
                duration: 5,
                onReverseComplete: () =>{
                    text2Part4InTween.seek(0);
                    text2Part4InTween.pause();
                },
                onComplete: () =>{
                    text2Part3InTween.seek(0.5);
                    text2Part3InTween.pause();
                }
            }, ">");
            tl1.to('.text2Part4', {
                duration: 0.01,
                onReverseComplete: () =>{
                    text2Part4InTween.reverse();
                },
                onStart: () =>{
                    text2Part4InTween.play();
                }
            }, ">");
            tl1.to('.text2Part4', {
                duration: 5,
                onReverseComplete: () =>{
                    text2OutTween.seek(0);
                    text2OutTween.pause();
                },
                onComplete: () =>{
                    text2Part4InTween.seek(0.5);
                    text2Part4InTween.pause();
                }
            }, ">");
            tl1.to('.text2', {
                duration: 0.01,
                onReverseComplete: () =>{
                    text2OutTween.reverse();
                },
                onStart: () =>{
                    text2OutTween.play();
                }
            }, ">-=1");
            

            tl1.to('.video_section1', {
                duration: 1, 
                autoAlpha:0 , 
                scale:2
            }, ">");

            //page2第一段文本
            tl1.to('.video_section2', {
                duration: 0.5,
                autoAlpha: 0.9,
                onStart : async()=>{
                    const vp = video_section2_ref.current;
                    if (vp && vp.paused){vp.currentTime = 0; try{await vp.play();}catch(e){console.error(e)}}
                },
            }, "page2<-=0.5");
            tl1.to('.page2LongText1', {
                duration: 1,
                onReverseComplete: async() =>{
                    page2LongText1InTween.pause();
                    if (page2LongText1RootRef?.current){
                        page2LongText1RootRef.current.style.top = "100%";
                        page2LongText1RootRef.current.style.visibility = "hidden";
                    }
                    const vp = video_section2_ref.current;
                    if (vp) {await vp.pause();}
                },
                onStart: () =>{
                    page2LongText1InTween.restart();
                }
            }, "page2<-=0.5");
            tl1.to('.page2LongText1', {
                duration: 4.5,
                onReverseComplete: () =>{
                    page2LongText1ChangeTween.seek(0);
                    page2LongText1ChangeTween.pause();
                },
                onComplete: () =>{
                    page2LongText1InTween.seek(0.5);
                    page2LongText1InTween.pause();
                }
            }, ">");
            tl1.to('.page2LongText1', {
                duration: 1,
                onReverseComplete: () => {
                    page2LongText1ChangeTween.reverse();
                    setLighLightLine(page2LongText1RefList, page2LongText1HighLightRefList, 0);
                },
                onComplete: () =>{
                    page2LongText1ChangeTween.play();
                    setLighLightLine(page2LongText1RefList, page2LongText1HighLightRefList, 1);
                }
            }, ">");
            tl1.to('.page2LongText1', {
                duration: 6.5,
                onReverseComplete: () =>{
                    page2ImageRootInTween.seek(0);
                    page2ImageRootInTween.pause();
                    page2LongText1OutTween.seek(0);
                    page2LongText1OutTween.pause();
                },
                onComplete: () =>{
                    page2LongText1ChangeTween.seek(0.5);
                    page2LongText1ChangeTween.pause();
                }
            }, ">");
            tl1.to('.page2LongText1', {
                duration: 0.01,
                onReverseComplete: () => {
                    page2LongText1OutTween.reverse();
                    page2ImageRootInTween.reverse();
                },
                onComplete: () =>{
                    page2LongText1OutTween.play();
                    page2ImageRootInTween.play();
                }
            }, ">");
            tl1.to('.page2ImageRoot', {
                duration: 10,
                onReverseComplete: () =>{
                    page2ImageRootOutTween.seek(0);
                    page2ImageRootOutTween.pause();
                },
                onComplete: () =>{
                    page2LongText1OutTween.seek(0.5);
                    page2LongText1OutTween.pause();
                    page2ImageRootInTween.seek(0.5);
                    page2ImageRootInTween.pause();
                }
            }, ">");
            tl1.to('.page2ImageRoot', {
                duration: 0.01, 
                onReverseComplete: () =>{
                    page2ImageRootOutTween.reverse();
                },
                onStart: () =>{
                    page2ImageRootOutTween.play();
                }
            }, ">");
            tl1.to('.page2ImageRoot', {
                duration: 1,
                onComplete: () =>{
                    page2ImageRootOutTween.seek(0.5);
                    page2ImageRootOutTween.pause();
                }
            }, ">");

            //page3
            tl1.to('.page3LongText1', {
                duration: 1,
                onReverseComplete: async() =>{
                    page3LongText1InTween.seek(0);
                    page3LongText1InTween.pause();
                    page3LongText1OutTween.seek(0);
                    page3LongText1OutTween.pause();
                    if (page3LongText1RootRef?.current){
                        page3LongText1RootRef.current.style.top = "100%";
                        page3LongText1RootRef.current.style.visibility = "hidden";
                    }
                },
                onStart: () =>{
                    page3LongText1InTween.restart();
                }
            }, "page3-=0.5");
            tl1.to('.page3LongText1', {
                duration: 4.5,
                onReverseComplete: () =>{
                    page3LongText1ChangeTween.seek(0);
                    page3LongText1ChangeTween.pause();
                },
                onComplete: () =>{
                    page3LongText1InTween.seek(0.5);
                    page3LongText1InTween.pause();
                }
            }, ">");
            tl1.to('.page3LongText1', {
                duration: 1,
                onReverseComplete: () => {
                    page3LongText1ChangeTween.reverse();
                    setLighLightLine(page3LongText1RefList, [], 0);
                },
                onComplete: () =>{
                    page3LongText1ChangeTween.play();
                    setLighLightLine(page3LongText1RefList, [], 1);
                }
            });
            tl1.to('.page3LongText1', {
                duration: 5.5,
                onReverseComplete: () =>{
                    page3LongText1OutTween.seek(0);
                    page3LongText1OutTween.pause();
                },
                onComplete: () =>{
                    page3LongText1ChangeTween.seek(0.5);
                    page3LongText1ChangeTween.pause();
                }
            });
            // tl1.to('.page3LongText1', {
            //     duration: 0.01,
            //     onReverseComplete: () => {
            //         page3LongText1OutTween.reverse();
            //     },
            //     onComplete: () =>{
            //         page3LongText1OutTween.play();
            //     }
            // }, ">");

            // tl1.to('.comingSoonText1', {
            //     duration: 1,
            //     onReverseComplete: () =>{
            //         comingSoonInTween.seek(0);
            //         comingSoonInTween.pause();
            //         comingSoon2InTween.seek(0);
            //         comingSoon2InTween.pause();
            //     },
            //     onStart: () =>{
            //         page3LongText1OutTween.seek(0.5);
            //         page3LongText1OutTween.pause();
            //         comingSoonInTween.restart();
            //     }
            // }, ">");
            // tl1.to('.comingSoon', {
            //     duration: 6
            // }, ">");

            onClickGoTop();
            
            if(window.history.scrollRestoration) {
                window.history.scrollRestoration = 'manual'
            }
            
            window.onload = (event) => {
                const currentScrollPrg = tl1.scrollTrigger.progress;
                console.log("fully loaded", currentScrollPrg);
                
                if (currentScrollPrg > 0){
                    //when images fully loaded, refresh timeline in-place
                    const changedPrg = Math.max(0, currentScrollPrg - 0.001);
                    window.scrollTo(0, changedPrg * ScrollTrigger.maxScroll(window, false));
                    tl1.progress(changedPrg);
                }
              };
        },

        { scope: rootRef }
    );

    const onClickGoTop = contextSafe(()=>{

        if (!tl1Ref.current) return;

        const tl = tl1Ref.current;
        const pos = tl.scrollTrigger.labelToScroll("text1");
        
        window.scrollTo(0, pos);
        tl.progress(pos / ScrollTrigger.maxScroll(window, false));

        console.log('onClickGoTop');
    });

    const onClickGoPhone = contextSafe(()=>{

        if (!tl1Ref.current) return;

        const tl = tl1Ref.current;
        const pos = tl.scrollTrigger.labelToScroll("buyPhone");

        window.scrollTo(0, pos);
        tl.progress(pos / ScrollTrigger.maxScroll(window, false));

        console.log('onClickGoPhone');
    });

	return(
		<div ref={rootRef} className={`${s.root} textRoot`}>
            <div ref={tlTriggerRef} className={`${s.tlTrigger} tlTrigger`}>
                {/*<div className={s.tlTriggerScroll}>
                </div>*/}
            </div>

            <video className={`${s.video_background} video_section1`} style={{opacity:0}} ref={video_section1_ref} autoPlay muted loop playsInline>
                <source src="./video/IYKYKSpinningGlobe.mp4" type="video/mp4" />
            </video>
            {/*<div className={`${s.phoneBg}`} style={{backgroundImage: `url(img/alphamobile_bg.png)`}}>

            </div>*/}
            <div className={`${s.midContent} text1`}>
                {
                    verticalMode ? 
                        <ReactSVG className={`${s.text1}`} src="./img/turnup_vertical_animate.svg" />:
                        <ReactSVG className={`${s.text1}`} src="./img/turnup_animate.svg" />
                }
                {/* <div className={s.intro}>In TURNUP, you can browse and invest points in short films that<br /> interest you, in return for more points in the future.</div> */}
                <div className={s.intro}>
                        <div>TURNUP is a premier moment trading platform designed to transform every interaction into an opportunity. Here’s how TURNUP works:</div>
                        <br /><div>1.Create: Users upload their moments, adding their creative touch to the platform.</div>
                        <br /><div>2.Trade: Moments are bought and sold based on a bonding curve, where early purchases are cheaper and later purchases are more expensive, creating a dynamic and fair trading environment.</div>
                        <br /><div>3.Earn: The TGE triggers once all moments are sold, leading to FT airdrops for participants.</div>
                </div>
                <div className={s.appButton} style={{backgroundImage: `url(img/UI_Button_Yellow-M_01.png)`}} onClick={()=>{
                    launchApp();
                }}>
                    Join TURNUP
                </div>
            </div>
            
            <div className={`${s.text2} ${verticalMode?s.verticalText2:s.horizontalText2} text2`} style={{fontSize: getText2Size(), color: 'white'}}>
                <div ref={text2Part1Ref} className={`${s.text2Part1} text2Part1`} style={{fontSize: getText2Size(), color: longTextColorLight, marginTop: verticalMode?'90px':'30px'}}>
                    {/* Web2:Social Networks */}
                    The Creator Network 
                </div>
                <div ref={text2Part2Ref} className={`${s.text2Part2} text2Part2`} style={{fontSize: getText2Size(), color: longTextColorLight, marginTop: verticalMode?'90px':'30px'}}>
                    {/* and Content Generation. */}
                    for Digital Moments
                </div><br/>
                <div ref={text2Part3Ref} className={`${s.text2Part3} text2Part3`} style={{fontSize: getText2Size(), color: longTextColorLight, marginTop: verticalMode?'90px':'30px'}}>
                    {/* Web3:Creator Network<br/> */}
                    The Creator Network <br/>
                </div>
                <div ref={text2Part4Ref} className={`${s.text2Part4} text2Part4`} style={{fontSize: getText2Size(), color: longTextColorLight, marginTop: verticalMode?'90px':'30px'}}>
                    {/* and Asset Creation. */}
                    Asset Ownership
                </div>
            </div>
            {/*<div className={`${s.text2} text2`} style={{fontSize: getText2Size(), color: 'white'}}>
                The First Modular Creator Network<br/> Built on Proof of Consensus
            </div>*/}

            <video className={`${s.video_background} video_section2`} style={{opacity:0, visibility:false}} ref={video_section2_ref} preload="auto" muted loop playsInline>
                <source src="./video/BlueBlockchain.mp4" type="video/mp4" />
            </video>
            <div ref={page2LongText1RootRef} className={`${s.page2LongText1} ${s.commonPageLongText} page2LongText1`}>
                <span style={{fontSize: getLongTextImportantTextSize(), color: longTextHighLightColor}}> 
                    {/* A Creator Network Powered By Gamified Liquidity */}
                    A Creator Network for the Future of Digital Asset
                </span><br/><br/>
                <span ref={page2LongText11Ref} style={{fontSize: getLongTextTextSize(), color: longTextColorGrayLight}}>
                   {/* Gamify your social capital using Dual Nature Assets, a new digital asset class. DNA merges the liquidity of fungible tokens with the composability of NFTs’ unique multi-media experiences, offering a versatile, engaging asset class. */}
                   TURNUP empowers users to capture and share their most valuable moments. With TURNUP, creators can seamlessly transform their digital content into unique, tradable assets. 
                </span>

                <span ref={page2LongText12Ref} style={{fontSize: getLongTextTextSize(), color: longTextColorGrayLight}}>
                    {/* &nbsp;Powered by gamified liquidity, TURNUP provides modular tools enabling anyone to create DNAs as building blocks for interactive community activities, games, and experiences. */}
                    &nbsp;Once all moments are sold, exclusive rewards are unlocked for the holders. TURNUP provides the tools for building interactive experiences and fostering a thriving community built around digital creation and ownership.
                    <span ref={page2LongText12HighLightRef} style={{color: longTextColorGrayLight}}>
                    </span>
                </span>
            </div>
            <div ref={page2ImageRootRef} className={`${s.page2ImageRoot} page2ImageRoot ${verticalMode?s.verticalPage2ImageRoot:s.horizontalPage2ImageRoot} ${isOverWidth?'':s.page2ImageRoll}`}>
                <div className={`${s.page2CommonImage}`} style={{backgroundImage: `url(img/HighThroughput.webp)`}}>
                </div>
                <div className={`${s.page2CommonImage}`} style={{backgroundImage: `url(img/ModularLayer2.webp)`}}>
                </div>
                <div className={`${s.page2CommonImage}`} style={{backgroundImage: `url(img/NativeTokenFramework.webp)`}}>
                </div>
                <div className={`${s.page2CommonImage}`} style={{backgroundImage: `url(img/NearInstantFidelity.webp)`}}>
                </div>
                {
                    isOverWidth ? 
                    <>
                    </>:
                    <>
                        <div className={`${s.page2CommonImage}`} style={{backgroundImage: `url(img/HighThroughput.webp)`}}>
                        </div>
                        <div className={`${s.page2CommonImage}`} style={{backgroundImage: `url(img/ModularLayer2.webp)`}}>
                        </div>
                        <div className={`${s.page2CommonImage}`} style={{backgroundImage: `url(img/NativeTokenFramework.webp)`}}>
                        </div>
                        <div className={`${s.page2CommonImage}`} style={{backgroundImage: `url(img/NearInstantFidelity.webp)`}}>
                        </div>
                    </>
                }
            </div>

            {/*<video className={`${s.video_background} video_section3`} style={{opacity:0, visibility:false}} ref={video_section3_ref} preload="auto" muted loop playsInline>
                <source src="./video/BlueMap.mp4" type="video/mp4" />
            </video>*/}

            <div ref={page3LongText1RootRef} className={`${s.page3LongText1} ${s.commonPageLongText} page3LongText1`}>
                <span style={{fontSize: getLongTextImportantTextSize(), color: longTextHighLightColor}}>
                    {/* Creator Network: Next-Gen SocialFi */}
                    A Hub for Digital Communities and Next-Generation Experiences
                </span><br/><br/>
                <span ref={page3LongText1P1Ref} style={{fontSize: getLongTextTextSize(), color: longTextColorLight}}>
                {/* It empowers creators to easily create and distribute assets using ecosystem modules, engaging with communities. */}
                TURNUP enables creators to easily create and share valuable digital moments using a range of modular tools. It fosters deeper engagement with communities by empowering creators to offer unique content and experiences. 
                </span>
                <span ref={page3LongText1P2Ref}  style={{fontSize: getLongTextTextSize(), color: longTextColorGrayLight}}>
                {/* &nbsp;With multi-chain compatibility, it allows deployment across multiple blockchains, increasing reach and liquidity. */}
                &nbsp;TURNUP’s flexible platform allows creators to expand their reach and interact with users across different ecosystems, enhancing the overall community experience.
                </span>
            </div>
            <div className={`${s.comingSoon} comingSoon`}>
                <div className={`${s.comingSoonText1} comingSoonText1`} style={{fontSize: getComingSoonText1FontSize()}}>
                    COMING SOON...
                </div>
                <div className={`${s.comingSoonText2} comingSoonText2`} style={{color: longTextHighLightColor, fontSize: getComingSoonText2FontSize(), marginTop: verticalMode?'20px':'0px'}}>
                    #IYKYK
                </div>
            </div>
            <div className={`${s.iconList} iconList`} ref={scrollContentRef} 
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                    
                    {partnerData.map((i,ind) => {
                        const { icon, width, height, url } = i;
                        return (
                                <div key={ind} className={s.item} >
                                    <CustomIcon
                                        key={i}
                                        imgType={'png'}
                                        imgName={`Logo/${icon}`}
                                        width={verticalMode?width * 0.4 :width / 2}
                                        height={verticalMode?height * 0.4 :height / 2}
                                        onClick={()=>{openUrl(url)}} />
                                </div>
                        )
                        
                    })}
                    {partnerData.map((i,ind) => {
                        const { icon, width, height, url } = i;
                        return (
                                <div key={ind} className={s.item} >
                                    <CustomIcon
                                        key={i}
                                        imgType={'png'}
                                        imgName={`Logo/${icon}`}
                                        width={verticalMode?width * 0.4 :width / 2}
                                        height={verticalMode?height * 0.4 :height / 2}
                                        onClick={()=>{openUrl(url)}} />
                                </div>
                        )
                        
                    })}
            </div>

            <div className={s.topBarRoot}>
                <CustomIcon className={s.socialIcon} imgName={'UI_Button_White_Twitter_01'} imgType={'png'} width={20} height={20}
                    onClick={()=>{
                        window.open("https://twitter.com/GoTurnUp");
                    }} />
                <CustomIcon className={s.socialIcon} imgName={'UI_Button_White_TurnUp_01'} imgType={'png'} width={20} height={20} 
                    onClick={()=>{
                        window.open("https://t.me/GoTurnUp");
                    }} />
                {/* <div className={s.appButton} style={{backgroundImage: `url(img/UI_Button_Yellow-M_01.png)`, marginRight: verticalMode?'10px':'50px'}} onClick={()=>{
                    launchApp();
                }}>
                    SIGN IN
                </div> */}
            </div>
            <CustomIcon className={s.turnupLogo} imgName={'UI_Picture-TURNUP_Horizontal_Tilted_02'} imgType={'png'} width={120} height={40} />
		</div>
	);

}

export default memo(AlphaPhoneVerticalTimeline);
